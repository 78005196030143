import styled from '@emotion/styled';
import theme from '@styles/theme';

// Define a type for the props
interface LayoutContainerProps {
  version?: string; // or a more specific type if applicable
}

export const LayoutContainer = styled(
  ({ version, ...rest }: LayoutContainerProps) => <div {...rest} />,
)`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  justify-content: space-between;
  background-color: ${(props) =>
    props.version == 'simple'
      ? theme.brand.colors.white
      : theme.brand.colors.black};

  > div:first-of-type {
    flex-shrink: 0;
    flex-basis: auto;
    flex: 1;
  }
`;
