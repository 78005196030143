import React, { Fragment, useEffect } from 'react';
import { Divider, Popup } from 'semantic-ui-react';

import { UserIcon, UserIconWrapper } from './UserLink.styles';
import {
  DropdownListItemMessage,
  DropdownListItemTitle,
  NavMenuLinkDropdownItemLink,
  NavMenuLinkDropdownMenu,
  UserAuthDropdownItem,
  UserAuthDropdownItemInfo,
} from '@components/elements/NavigationMenuDropdown/NavigationMenuDropdown.styles';
//import { isLoggedIn } from "@auth/auth"
//import Auth, { CognitoUser } from ""
import { redirect, useNavigate } from '@remix-run/react';
// import { processSignOut } from "~/utils/Helpers"
import { fetchUserAttributes } from 'aws-amplify/auth';
import { useAuthContext } from '~/contexts/AuthContext';
//import { navigate } from "gatsby"

interface UserPopupProps {
  apiUrl: string;
}

const UserPopup = (props: UserPopupProps) => {
  const { user } = useAuthContext();
  // const [username, setUsername] = React.useState<string | undefined>(undefined);

  // useEffect(() => {
  //   fetchUserAttributes().then((userAttributes) => {
  //     setUsername(userAttributes.name);
  //   });
  // }, []);

  return (
    <Fragment>
      {user.username !== undefined ? (
        <LoggedInUserPopup username={user.username} />
      ) : (
        <AnonymousUserPopup />
      )}
    </Fragment>
  );
};

const AnonymousUserPopup = () => {
  return (
    <NavMenuLinkDropdownMenu>
      <UserAuthDropdownItem>
        <NavMenuLinkDropdownItemLink>
          <DropdownListItemTitle>My Account</DropdownListItemTitle>
        </NavMenuLinkDropdownItemLink>
      </UserAuthDropdownItem>
      <Divider />
      <UserAuthDropdownItem>
        <NavMenuLinkDropdownItemLink url="/signup">
          <DropdownListItemTitle>Create an Account</DropdownListItemTitle>
        </NavMenuLinkDropdownItemLink>
      </UserAuthDropdownItem>
      <UserAuthDropdownItem>
        <NavMenuLinkDropdownItemLink url="/login">
          <DropdownListItemTitle>Log In</DropdownListItemTitle>
        </NavMenuLinkDropdownItemLink>
      </UserAuthDropdownItem>
    </NavMenuLinkDropdownMenu>
  );
};

const LoggedInUserPopup = (props: { username: string }) => {
  const navigate = useNavigate();
  const { isUserLoggedIn, user, logout } = useAuthContext();

  const signOut = async () => {
    await logout();
    navigate('/login');
  };

  return (
    <NavMenuLinkDropdownMenu>
      <UserAuthDropdownItemInfo>
        <NavMenuLinkDropdownItemLink>
          <DropdownListItemMessage>
            Hi, {props.username}
          </DropdownListItemMessage>
        </NavMenuLinkDropdownItemLink>
      </UserAuthDropdownItemInfo>
      <Divider />
      <UserAuthDropdownItem>
        <NavMenuLinkDropdownItemLink url="/app/listings">
          <DropdownListItemTitle>My Orders</DropdownListItemTitle>
        </NavMenuLinkDropdownItemLink>
      </UserAuthDropdownItem>
      <UserAuthDropdownItem>
        <NavMenuLinkDropdownItemLink url="/app/profile">
          <DropdownListItemTitle>My Details</DropdownListItemTitle>
        </NavMenuLinkDropdownItemLink>
      </UserAuthDropdownItem>
      <UserAuthDropdownItem onClick={signOut}>
        <DropdownListItemTitle>Sign Out</DropdownListItemTitle>
      </UserAuthDropdownItem>
    </NavMenuLinkDropdownMenu>
  );
};

interface UserLinkProps {
  isSimple?: boolean;
  apiUrl: string;
}

const UserLink = (props: UserLinkProps) => {
  return (
    <Popup
      trigger={
        <UserIconWrapper>
          <UserIcon name="user circle" size="huge" />
        </UserIconWrapper>
      }
      flowing
      on="click"
      position="bottom right"
    >
      <UserPopup apiUrl={props.apiUrl} />
    </Popup>
  );
};

export default UserLink;
