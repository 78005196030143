import React from 'react';
import {
  MessageInfoBar,
  MessageInfo,
  DismissIcon,
} from './MessageBanner.styles';
import { isCheckoutCertain } from '~/utils/Helpers';
//import { getCurrentCheckout } from "@redux/localStorage/checkout";

interface MessageBannerProps {
  backgroundColor: string | string[];
  messageHtml: string;
  id: string;
  textColor: string | string[];
  hide: (id: string) => void;
}

export const MessageBanner = (props: MessageBannerProps) => {
  const { backgroundColor, messageHtml, id, textColor } = props;
  console.log('messageHtml', messageHtml);
  //const checkout = getCurrentCheckout()
  //const isDealerOrder = checkout && checkout.isDealerOrder ? true : false;

  return (
    <React.Fragment>
      {/* {typeof document != "undefined" && isCheckoutCertain(document.location.href) === false && !isDealerOrder && */}
      {typeof document != 'undefined' &&
        isCheckoutCertain(document.location.href) === false && (
          <MessageInfoBar
            backgroundColor={backgroundColor}
            className="Banner"
            id="Banner"
          >
            <MessageInfo
              dangerouslySetInnerHTML={{ __html: messageHtml }}
              textColor={textColor}
            />
            <DismissIcon name="times" onClick={() => props.hide(id)} />
          </MessageInfoBar>
        )}
    </React.Fragment>
  );
};
