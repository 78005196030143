interface FooterLinkListProp{
    title:{
        value:string
    }
    links: {
        linkedItems: Array<{
            elements:{
                url: {
                    value: string
                } | undefined
                generalPageContentTitle:{
                    value: string
                }
                pageInfoCheckoutTitle:{
                    value: string
                } | undefined
            }
        }>
    }
}
export const mapToFooterLinks = (linkList: {linkedItems: Array<{elements:FooterLinkListProp}>}) =>{
    const result = linkList.linkedItems.map(list=>mapToLinkList(list.elements));
    return {
        first: result[0],
        second: result[1],
        third: result[2]
    }
}

interface FooterLinkItem{
    title: string
    url: string
}
export const mapToLinkList = (list:FooterLinkListProp) =>{
    const {title,links} = list;
    const linkList:FooterLinkItem[] = [];
    links.linkedItems.forEach(item=>{
        if(item.elements !== undefined){
            const {url, generalPageContentTitle,pageInfoCheckoutTitle} = item.elements;
            const title = generalPageContentTitle === undefined ? pageInfoCheckoutTitle : generalPageContentTitle;
            linkList.push({
                title: title === undefined ? '' : title.value === undefined ? "" : title.value,
                url: url !== undefined ? url.value : ''
            });
        }
    })
    return {
        title: title.value,
        links: linkList
    }
}

type CustomerReviewCardProp = {
    totalreviews:{
        value: number
    },
    rating:{
        value: number
    }
}
export const mapToCustomerReviewCard = (element: CustomerReviewCardProp) =>{
    
    const totalReview = element.totalreviews.value;
    const rating = element.rating.value;
    return {
        totalReview,
        rating
    }
}