import React, { useEffect } from 'react';
import DesktopLayoutContainer from './DesktopLayoutContainer';
import pkg from 'lodash';
import ThemeWrapper from './ThemeWrapper';
// import { RootLoaderProps } from '~/root'
import MobileLayoutContainer from './MobileLayoutContainer';
import { useFetcher } from '@remix-run/react';
import { RootLoaderProps } from '~/root';
const { debounce } = pkg;

export const currentDateTime: Date = new Date();

export const showMessage = (
  startDate: string | undefined,
  endDate: string | null,
) => {
  if (endDate === null) {
    return true;
  } else {
    const ed = new Date(endDate);
    if (ed >= currentDateTime) {
      if (startDate !== undefined) {
        const sd = new Date(startDate);
        return currentDateTime >= sd;
      }
      return true;
    } else {
      return false;
    }
  }
};
export const showPopupOnPage = (allowedUrls: string[]) => {
  if (typeof window !== 'undefined') {
    const allowed = allowedUrls.find((url: string) => {
      return (
        url.toLowerCase().replaceAll('/', '') ===
        window.location.pathname.toLowerCase().replaceAll('/', '')
      );
    });
    return allowed !== undefined;
  } else {
    return false;
  }
};
export const showMessageBanner = (key: string) => {
  if (typeof window !== 'undefined') {
    return localStorage.getItem(key) === null ? true : false;
  } else {
    return false;
  }
};

interface ResponsiveLayoutContainerProps {
  children: React.ReactNode;
  data: RootLoaderProps;
  version: string;
  currentStep?: number | undefined;
}

let lastScrollY = 0;

const ResponsiveLayoutContainer: React.FunctionComponent<
  ResponsiveLayoutContainerProps
> = ({ children, data, version, currentStep }) => {
  const [state, setState] = React.useState({
    width: 768,
    showPopupOnClose: false,
  });

  const handleMouseMove = (e: { clientX: number; clientY: number }) => {
    //const mx = e.clientX;
    const my = e.clientY;
    if (my < 5) {
      setState((prevState) => ({ ...prevState, showPopupOnClose: true }));
    }
  };

  const handleScroll = debounce(() => {
    const currentScrollY = window.scrollY;
    if (currentScrollY < lastScrollY - 5) {
      //scrolling up
      lastScrollY = currentScrollY;
      setState((prevState) => ({ ...prevState, showPopupOnClose: true }));
    } else {
      lastScrollY = currentScrollY;
    }
  }, 200);

  React.useEffect(() => {
    if (typeof window === 'undefined') return;

    handleWindowSizeChange(); // Set width
    window.addEventListener('resize', handleWindowSizeChange);
    window.addEventListener('mousemove', handleMouseMove);
    window.addEventListener('scroll', handleScroll, true);

    return () => {
      window.removeEventListener('resize', handleWindowSizeChange);
      window.removeEventListener('mousemove', handleMouseMove);
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const handleWindowSizeChange = () => {
    setState((prevState) => ({ ...prevState, width: window.innerWidth }));
  };

  return (
    <ThemeWrapper themeName="dark">
      <div className="hidden lg:block">
        <DesktopLayoutContainer
          {...{ data, version, currentStep }}
          windowWidth={state.width}
          beforeCloseFired={state.showPopupOnClose}
        >
          {children}
        </DesktopLayoutContainer>
      </div>
      <div className="block lg:hidden">
        <MobileLayoutContainer
          {...{ data, version, currentStep }}
          windowWidth={state.width}
          beforeCloseFired={state.showPopupOnClose}
        >
          {children}
        </MobileLayoutContainer>
      </div>
    </ThemeWrapper>
  );
};

export const LayoutWrapper = ({
  children,
  version,
  currentStep,
}: {
  children: React.ReactNode;
  version: string;
  currentStep?: number;
}) => {
  const fetcher = useFetcher();
  useEffect(() => {
    fetcher.load('/api/layout-data');
  }, []);

  const data = fetcher.data as RootLoaderProps;

  if (typeof data === 'undefined') {
    return <div></div>;
  }

  return (
    <>
      {
        // authEnabled ?
        //   <AuthProvider>
        //     <ResponsiveLayoutContainer
        //       apiUrl={data.site.siteMetadata.apiUrl}
        //       emailValidationToken={data.site.siteMetadata.emailValidationToken}
        //       siteBuildInfo={data.site.siteMetadata}
        //       navItems={data.allKontentItemNavigationItemV2.nodes}
        //       announcementItems={data.allKontentItemAnnouncement.nodes}
        //       promotionPopupItems={data.allKontentItemPromotionPopup.nodes}
        //       promotionPopupForms={data.allKontentItemCompetitionPopupForm.nodes}
        //       version={version}
        //       currentStep={currentStep} >
        //       {children}
        //     </ResponsiveLayoutContainer>
        //   </AuthProvider>
        //   :
        <ResponsiveLayoutContainer
          data={data}
          version={version}
          currentStep={currentStep}
        >
          {children}
        </ResponsiveLayoutContainer>
      }
    </>
  );
};
