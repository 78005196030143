import React, { Fragment } from 'react';
import {
  MobileHeaderSegment,
  MobileHeaderLogo,
  SidebarIcon,
  SimpleMobileHeaderSegment,
  PhoneLinkWrapper,
  PhoneLink,
  MobileNavWrapper,
  DarkMobileHeaderSegment,
} from './Header.styles';
import { isMarketplacePages } from './DesktopPageHeader';
import theme from '@styles/theme';
import { Icon } from 'semantic-ui-react';
// import { Checkout } from "@dataModels/Checkout/Checkout"
import { Link, redirect, useNavigate } from '@remix-run/react';
import { PhoneNavigation } from '~/assets/svgs';
import ShoppingCartContainer from '../ShoppingCartLink/ShoppingCartLink';
import { ProgressStatusBar } from '../ProgressStatusBar';
import UserLink from '../elements/UserLink/UserLink';
import { BasketLineResponseModel } from '~/dataModels/BasketModel/BasketLineResponseModel';
import { useAuthContext } from '~/contexts/AuthContext';
import { useCartStore } from '~/stores/cartStore';
// import { processSignOut } from "~/utils/Helpers"

interface MobilePageHeaderProps {
  apiUrl: string;
  handleToggleSidebar: () => void;
  currentStep?: number;
  sidebarOpened: boolean;
  version: string;
  // checkout: Checkout;
  // cartLineItems: BasketLineResponseModel[];
  // updatePersonalDetails: (personalDetails: Checkout) => void
  // updateDeliveryAddress: (deliveryAddress: Address) => void
  // updateDealerAddress: (dealerAddress: Address) => void
  // updateIsDealerOrder: (isDealerOrder: boolean) => void
  // updateIsAcceptStatement: (isAcceptStatement: boolean) => void
}

export const MobilePageHeader = (props: MobilePageHeaderProps) => {
  const {
    apiUrl,
    handleToggleSidebar,
    currentStep,
    version,
    // cartLineItems,
    sidebarOpened,
  } = props;
  const navigate = useNavigate();
  const { isUserLoggedIn, user, logout } = useAuthContext();
  const { basket, getTotalItems } = useCartStore();
  React.useEffect(() => {
    if (
      version === 'simple' &&
      currentStep &&
      currentStep > 2 &&
      currentStep <= 4 &&
      getTotalItems() < 1
    ) {
      navigate('/shopping-cart');
    }
  }, [version, currentStep]);

  const isSimple = version !== 'standard';
  const isMarketplacePage = isMarketplacePages();

  const handleSignOut = async () => {
    await logout();
    navigate('/dealer-login');
  };

  return (
    <MobilePageHeaderWrapper currentStep={currentStep} version={version}>
      <div className="flex justify-between items-center">
        <div className="flex gap-4">
          <SidebarIcon
            name="sidebar"
            size="big"
            onClick={handleToggleSidebar}
          />
          <Link
            to={isUserLoggedIn && user.isDealer == true ? '/dealer-login' : '/'}
            aria-label="Homepage"
          >
            <MobileHeaderLogo width={115} height={34} reverse={true} />
          </Link>
        </div>
        <div className="mt-0 md:mt-0">
          <MobileNavWrapper className="flex justify-between items-center">
            {version === 'simple' ? (
              isUserLoggedIn && user.isDealer == true ? (
                <PhoneLinkWrapper onClick={handleSignOut}>
                  <Icon name="sign-out" size="huge" />
                </PhoneLinkWrapper>
              ) : (
                <PhoneLinkWrapper>
                  <PhoneLink href="tel:0800650111">
                    <PhoneNavigation />
                  </PhoneLink>
                </PhoneLinkWrapper>
              )
            ) : (
              isMarketplacePage &&
              version != 'dealer' && <UserLink apiUrl={apiUrl} />
            )}

            {!sidebarOpened && (
              <ShoppingCartContainer
                isSimple={isSimple}
                apiUrl={apiUrl}
                fill={theme.brand.colors.white}
              />
            )}
          </MobileNavWrapper>
        </div>
      </div>
    </MobilePageHeaderWrapper>
  );
};

const MobilePageHeaderWrapper = ({
  version,
  currentStep,
  children,
}: {
  version: string;
  currentStep?: number;
  children: React.ReactNode;
}) => {
  return (
    <Fragment>
      {version === 'standard' ? (
        <MobileHeaderSegment basic>{children}</MobileHeaderSegment>
      ) : version === 'dark' ? (
        <DarkMobileHeaderSegment basic>{children}</DarkMobileHeaderSegment>
      ) : (
        <Fragment>
          <SimpleMobileHeaderSegment basic>
            {children}
          </SimpleMobileHeaderSegment>
          {currentStep !== undefined && currentStep >= 3 && (
            <ProgressStatusBar isMobile={true} currentStep={currentStep} />
          )}
        </Fragment>
      )}
    </Fragment>
  );
};

export default MobilePageHeader;
