//import { PromotionPopupModel } from "@dataModels/PromotionPopup/PromotionPopup"
//import { Modal, Icon } from "semantic-ui-react"
//import { InfoModalButton } from "@components/InfoModal/InfoModal.styles"
import { PromotionPopupModal, PromotionPopupModalBackgroundImage, PromotionPopupModalCloseIcon, PromotionPopupModalLink } from "./PromotionModal.styles"
//import { link } from "fs"
//import { Link } from "gatsby"
//import { backgroundImages } from "polished"
import { validatePromoService } from "@services/BasketService"
import { matchTagRegex } from "~/utils/Helpers"
import { PromotionPopupModel } from "~/dataModels/PromotionPopup/PromotionPopup"
//import { getCurrentBasket } from "../localStorage/basket"
//import { getCurrentBasket } from "@redux/localStorage/basket"
//import { currentBasketSelector } from "@redux/selectors/basketSelector"
//import { deleteBasketLineRequest } from '@redux/actions/deleteBasketLine';
//import { refreshBasketRequest } from '@redux/actions/refreshBasket';

interface Props {
  data: PromotionPopupModel,
  hide: (id:string)=>void,
  id:string,
  promoCode: string
  //refreshBasket: (apiUrl: string, basketId: string) => void
  apiUrl: string
}

const refreshBasket = (apiUrl: string, basketId: string) => {}

export const PromotionModal = (props: Props) =>{ 

  const applyPromotionCode = () => {
    if (matchTagRegex("test")) {
        return
    }
    // const basket = getCurrentBasket();
    // if(!basket.summary.promotionCode){
    //   validatePromoService(props.apiUrl, props.promoCode, basket.basketId).then((data: any) => {
    //     if (data.IsValid) {
    //         refreshBasket(props.apiUrl, basket.basketId);
    //     } 
    // })
    // }    
  }
  
  const {BackgroundImage, linkUrl} = props.data;

  return (
    <PromotionPopupModal open={true} onClose={()=>props.hide(props.id)}>
        <PromotionPopupModalCloseIcon name='x' size='massive' onClick={()=>props.hide(props.id)}/>
        <PromotionPopupModalLink to={linkUrl} onClick={()=>{props.hide(props.id); applyPromotionCode()}}>
          <PromotionPopupModalBackgroundImage alt={BackgroundImage.description} src={BackgroundImage.url} />
        </PromotionPopupModalLink>
    </PromotionPopupModal>
  )  
}
// const mapStateToProps = (state: Record<any>) => {
//   const cart = currentBasketSelector(state).toJS();
//   return {
//       cartId: cart.basketId,
//       cartLineItems: cart.basketLines,
//       summary: cart.summary,
//       loading: cart.loading,
//       success: cart.success
//   };
// }
// const mapDispatchToProps = (dispatch: Dispatch) => ({  
//   refreshBasket(apiUrl: string, basketId: string) {
//       dispatch(refreshBasketRequest(apiUrl, basketId))
//   },
// })

// const PromotionModal = connect(mapStateToProps, mapDispatchToProps)(PromotionModalView)
// export default PromotionModal;