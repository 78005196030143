import React, { useState } from 'react';
import { mapToAnnouncementItems, mapToTopNavMenu } from '../Layout/mapper';
import { MessageBanner } from '../MessageBanner/MessageBanner';
import { showMessage, showMessageBanner, showPopupOnPage } from './Layout';
import { LayoutContainer } from './DesktopLayoutContainer.styles';
import theme from '~/styles/theme';
import DesktopPageHeader from '../PageHeader/DesktopPageHeader';
import { hash } from '~/utils/Helpers';
import FooterWrapper from '../Footer/Footer';
// import { RootLoaderProps } from "~/root";
import {
  mapToPromotionPopupForms,
  mapToPromotionPopups,
} from '../PromotionPopup/mapper';
import { PromotionModal } from '../PromotionModal/PromotionModal';
import { PromotionFormModal } from '../PromotionModal/PromotionFormModal';
import { getEmptyBasket } from '~/stateManagement/localStorage/basket';
// import { getEmptyCheckout } from "~/stateManagement/localStorage/checkout";
import { checkDesignForPromoService } from '~/services/BasketService';
// import { useOrderContext } from "~/contexts/OrderContext";
import { BasketResponseModel } from '~/dataModels/BasketModel';
// import { Checkout } from "~/dataModels/Checkout/Checkout";
import { BasketResponseSummary } from '~/dataModels/BasketModel/BasketResponseModel';
import { RootLoaderProps } from '~/root';
import { useClientEnv } from '~/contexts/ClientEnvContext';

interface DesktopContainerProps {
  children: React.ReactNode;
  windowWidth: number;
  beforeCloseFired: boolean;
  data: RootLoaderProps;
  version: string;
  currentStep?: number;
}

interface State {
  basket: BasketResponseModel;
  // checkout: Checkout;
  summary: BasketResponseSummary;
}

const DesktopLayoutContainer: React.FC<DesktopContainerProps> = (props) => {
  const { apiUrl, emailValidationToken } = useClientEnv();
  const [activeIndex, setActiveIndex] = React.useState(-1);
  const [sidebarOpened, setSidebarOpened] = React.useState(false);
  const [updatingMessageBanner, setUpdatingMessageBanner] =
    React.useState(false);
  const [allowDesign, setAllowDesign] = React.useState(false);
  // const orderContext = useOrderContext();
  // const { checkout, basket } = orderContext ?? {
  //   checkout: getEmptyCheckout(),
  //   basket: getEmptyBasket(),
  //   summary: {} as BasketResponseSummary,
  // };

  // React.useEffect(() => {
  //   const fetchData = async () => {
  //     if (basket) {
  //       if (basket.basketLines && basket.basketLines.length > 0) {
  //         const popups = mapToPromotionPopups(data.promotionPopups);
  //         let promoCode = '';
  //         popups.forEach((popup) => {
  //           if (
  //             showMessage(popup.startDate, popup.endDate) &&
  //             showPopupOnPage(popup.allowedURLs)
  //           ) {
  //             promoCode = popup.promoCode;
  //           }
  //         });
  //         if (promoCode && checkout && !checkout.isDealerOrder) {
  //           checkDesignForPromoService(
  //             data.siteMetadata.apiUrl,
  //             promoCode,
  //             basket.basketId,
  //           ).then((data) => {
  //             if (data && data.Success && data.Data) {
  //               setAllowDesign(true);
  //             }
  //           });
  //         }
  //       }
  //     }
  //   };
  //   fetchData();
  // }, [allowDesign]);

  const removeMessageBanner = () => {
    setUpdatingMessageBanner(true);
    // if (typeof window !== "undefined") {
    //   localStorage.setItem("banner-dismissed", "true");
    // }
    // setUpdatingMessageBanner(false);
  };

  const renderPromotionPopups = () => {
    const popups = mapToPromotionPopups(props.data.promotionPopups);
    const result: any[] = [];
    popups.forEach((popup, key) => {
      const id = popup.id.replace(' ', '');
      if (
        showMessage(popup.startDate, popup.endDate) &&
        !updatingMessageBanner &&
        showPopupOnPage(popup.allowedURLs) &&
        allowDesign
      ) {
        result.push(
          <PromotionModal
            key={key}
            hide={removeMessageBanner}
            data={popup}
            id={id}
            promoCode={popup.promoCode}
            apiUrl={apiUrl}
          />,
        );
      }
    });
    return result;
  };
  const renderPromotionPopupForms = () => {
    const popups = mapToPromotionPopupForms(props.data.competitionPopupForms);
    const forms: any[] = [];
    popups.forEach((popup, key) => {
      const id = 'desktop-' + hash(popup.name);
      const showbanner = showMessageBanner(id);
      if (
        showMessage(popup.startDate, popup.endDate) &&
        showbanner &&
        showPopupOnPage(popup.allowedURLs)
      )
        forms.push(
          <PromotionFormModal
            emailValidationToken={emailValidationToken}
            key={id}
            isMobile={false}
            apiUrl={apiUrl}
            show={props.beforeCloseFired}
            hide={removeMessageBanner}
            data={popup}
            id={id}
          />,
        );
    });
    return forms;
  };

  const renderAnnouncements = () => {
    const announcements = mapToAnnouncementItems(props.data.announcements);

    const result: any[] = [];
    announcements.forEach((ats, key) => {
      const id = hash(ats.title);
      if (showMessage(undefined, ats.expireDate) && showMessageBanner(id)) {
        console.log('announcements', ats);
        result.push(
          <MessageBanner
            key={key}
            hide={removeMessageBanner}
            backgroundColor={ats.backgroundColor}
            messageHtml={ats.title}
            textColor={ats.textColor}
            id={id}
          />,
        );
      }
    });
    return result;
  };

  const { children, version, currentStep } = props;
  const topNavMenu = mapToTopNavMenu(props.data.navItems);
  return (
    <LayoutContainer {...props}>
      <div style={{ position: 'relative' }}>
        {renderAnnouncements()}
        {renderPromotionPopups()}
        {renderPromotionPopupForms()}
        <DesktopPageHeader
          apiUrl={apiUrl}
          currentStep={currentStep}
          topNavMenu={topNavMenu}
          version={version}
          // checkout={checkout}
          // cartLineItems={basket?.basketLines}
        />
        {children}
      </div>
      {version !== 'blank' && (
        <FooterWrapper
          version={version}
          backgroundColor={theme.brand.colors.black}
          customerReviewsCardBanner={props.data.customerReviewsCardBanner}
          footerLinks={props.data.footerPage}
        />
      )}
    </LayoutContainer>
  );
};

export default DesktopLayoutContainer;
