import React from "react"

import {
  NavMenuInternalLinkItem,
  NavMenuExternalLinkItem,
  NavMenuLinkItemText,
  NavMenuLinkItemContent,
  Divider
} from "./NavigationMenuLink.styles"

import { NavListItem,NavListItemDivider } from "@components/elements/NavigationMenuDropdown/NavigationMenuDropdown.styles"


interface LinkProps{
  text: string;
  linkUrl: string;
}

const NavigationMenuLink = ({ text, linkUrl ='/' }:LinkProps) => {
  const isExternalUrl = linkUrl.startsWith("http");
  return (
    <NavListItem>
      {
        isExternalUrl ? <ExternalMenuLink text={text} linkUrl={linkUrl} /> : <InternalMenuLink text={text} linkUrl={linkUrl} />
      }
    </NavListItem>
  );
}

const ExternalMenuLink = ({text, linkUrl}:LinkProps) =>{
  return (
      <NavMenuExternalLinkItem href={linkUrl}>
        <NavMenuLinkItemContent>
          <NavMenuLinkItemText>{text}</NavMenuLinkItemText>
        </NavMenuLinkItemContent>
      </NavMenuExternalLinkItem>
  );
}

const InternalMenuLink = ({text, linkUrl}:LinkProps) =>{
  const internalLinkUrl = linkUrl.endsWith('/') ? linkUrl : linkUrl + "/"
  return (
        <NavMenuInternalLinkItem to={internalLinkUrl}>
          <NavMenuLinkItemContent>
            <NavMenuLinkItemText>{text}</NavMenuLinkItemText>
          </NavMenuLinkItemContent>
        </NavMenuInternalLinkItem>
  );
}

export const NavDivider = () =>{
  return <NavListItemDivider>
    <Divider />
  </NavListItemDivider>
}
export default NavigationMenuLink
