import { Announcement } from '~/_models';
import { convertKontentColorToHex } from '~/utils/Helpers';

export type NavItem = {
  title: string;
  url: string;
  order: number;
  sub_nav: {
    title: string;
    url: string;
  }[];
};

export type AnnouncementItems = {
  elements: AnnouncementItem;
};
type AnnouncementItem = {
  title: string;
  titleDesktopTablet: {
    value: string;
  };
  titleMobile: {
    value: string;
  };
  textColors: {
    value: [
      {
        name: string;
        codename: string;
      },
    ];
  };
  backgroundColors: {
    value: [
      {
        name: string;
        codename: string;
      },
    ];
  };
  expiredDate: {
    value: string | null;
  };
};
export type TopNavMenuResult = {
  title: string;
  url: string;
  menu: Array<{
    title: string;
    url: string;
  }>;
  order: number;
};

export const mapToAnnouncementItems = (announcements: Announcement[]) => {
  const items = announcements.map((announcement) =>
    mapToAnnouncementItem(announcement),
  );
  return items;
};
const mapToAnnouncementItem = (item: Announcement) => {
  const {
    backgroundColors,
    textColors,
    titleDesktopTablet,
    titleMobile,
    expiredDate,
  } = item.elements;
  return {
    title: titleDesktopTablet.value,
    titleMobile: titleMobile.value,
    backgroundColor: convertKontentColorToHex(
      backgroundColors.value[0].codename,
    ),
    textColor: convertKontentColorToHex(textColors.value[0].codename),
    expireDate: expiredDate.value,
  };
};
export const mapToTopNavMenu = (data: any): NavItem[] => {
  const items = data
    .filter((item: any) => item.elements.subNav.linkedItems.length > 0)
    .map((item: any) => mapToTopNavMenuItem(item.elements));
  const result = items.sort((a: any, b: any) => (a.order > b.order ? 1 : -1));
  return result;
};

const mapToTopNavMenuItem = (data: any): NavItem => {
  const { title, url, order, subNav } = data;
  const subMenuCount = subNav.linkedItems.length;
  const subMenu =
    subMenuCount === 0
      ? []
      : subNav.linkedItems.map((item: any) => {
          const itemUrl = item.elements.url.value;
          const itemTitle = item.elements.title.value;
          return {
            url: itemUrl,
            title: itemTitle,
          };
        });
  return {
    title: title.value,
    url: url.value,
    sub_nav: subMenu,
    order: order.value,
  };
};
