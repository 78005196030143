import React, { useState, useEffect } from "react"
import { Formik } from "formik"
import { Grid, Form } from "semantic-ui-react"
// import {
//   buildValidationSchema,
//   buildFormInitialValues,
// } from "@components/elements/Forms/helper"
// import { KpBaseForm } from "@components/elements/Forms/KpBaseForm"
// import { FormFieldData } from "@components/elements/Forms/types"
// import { Paragraph1 } from "@styles/Global.styles"
// import theme from "@styles/theme"
import { SubscriptionService } from "@services/index"
import { EmailValidationService } from "@services/EmailValidationService"
import { buildFormInitialValues, buildValidationSchema } from "../elements/Forms/helper"
import { KpBaseForm } from "../elements/Forms/KpBaseForm"
import { Paragraph1 } from "~/styles/Global.styles"
import theme from "~/styles/theme"

interface CompetitionPopupFormProps {
  apiUrl: string
  emailValidationToken: string
  fields: FormFieldData[]
  campaignName: string
  competitionName: string
  id: string
  success: string
}

interface CompetitionPopupFormStates {
  currentEmail: string
  validatingEmail: boolean
  emailIsValid?: boolean
}

export const CompetitionPopupForm = (props: CompetitionPopupFormProps) => {
 
  const [state, setState] = useState<CompetitionPopupFormStates>({
    currentEmail: "",
    validatingEmail: false,
    emailIsValid: undefined
  })

  const validationSchema = buildValidationSchema(props.fields)

  const intialStatus = {
    isSubmitting: false,
    isSubmitted: false,
  }

  const insitialValues = buildFormInitialValues(props.fields)

  const validateEmailAddress = (value: any) => {
    if (state.currentEmail !== value) {
      setState({
        ...state,
        currentEmail: value,
        validatingEmail: true,
        emailIsValid: undefined
      })
      let error
      return EmailValidationService.validate(
        props.emailValidationToken,
        value
      ).then((result: any) => {
        if (result.success == true) {
          setState({ ...state, validatingEmail: false, emailIsValid: false })
        } else {
          error = "Invalid Email"
          setState({ ...state, validatingEmail: false, emailIsValid: false })    
          return error
        }
      })
    } else if (state.emailIsValid == false) {
      return "Invalid Email"
    }
  }

  const submitForm = (values: any, actions: any) => {
    if (!state.validatingEmail) {
      actions.setStatus({
        isSubmitting: true,
      })
      SubscriptionService.subscribeUserRequest(
        props.apiUrl,
        values,
        props.competitionName,
        {
          campaignName: props.campaignName
        }, false
      ).then(() => {
        actions.setStatus({
          isSubmitting: false,
          isSubmitted: true,
        })
      })
    } else {
      actions.setStatus({ isSubmitting: false })
    }
  }
  
    const { success } = props
    return (
      <Grid>
        <Grid.Row>
          <Grid.Column mobile={16} tablet={16} computer={16}>
            <Formik
              initialValues={insitialValues}
              validationSchema={validationSchema}
              initialStatus={intialStatus}
              validateOnChange={false}
              onSubmit={(values: any, actions: any) =>
                submitForm(values, actions)
              }
            >
              {({ handleSubmit, ...props }) => (
                <Form onSubmit={handleSubmit}>
                  <Grid columns={16}>
                    <Grid.Row>
                      {!props.status.isSubmitted && (
                        <Grid.Column mobile={16} tablet={16} computer={14}>
                          <KpBaseForm
                            validateEmailAddress={validateEmailAddress}
                            validatingEmail={state.validatingEmail}
                            emailIsValid={state.emailIsValid}                            
                            fullWidth={true}
                          />
                        </Grid.Column>
                      )}
                      {props.status.isSubmitted && (
                        <Grid.Column verticalAlign="middle" width={16}>
                          <Paragraph1
                            color={theme.brand.colors.black}
                            dangerouslySetInnerHTML={{ __html: success }}
                          ></Paragraph1>
                        </Grid.Column>
                      )}
                    </Grid.Row>
                  </Grid>
                </Form>
              )}
            </Formik>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    )
  
}
