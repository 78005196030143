import React, { Fragment } from 'react';
import theme from '@styles/theme';
import { Menu, Accordion, List, Sidebar } from 'semantic-ui-react';
import {
  SidebarTitle,
  SidebarNavItem,
  SidebarNavItemMenu,
  NavSubMenu,
  HamburgerIcon,
  SubMenuIcon,
  NavAccordionTitle,
  NavAccordionContent,
  ExternalNavLink,
  SidebarHeader,
  NavSubMenuItem,
  LogoIcon,
  LogoLink,
  SidebarPushableContainer,
  MobileTopMessageWrapper,
  SidebarContainer,
  MobileShoppingCartContainer,
  SidebarNavItemsContainer,
} from './MobileLayoutContainer.styles';

import { MessageBanner } from '../MessageBanner/MessageBanner';
import { hash } from '~/utils/Helpers';
import { showMessage, showMessageBanner, showPopupOnPage } from './Layout';
import {
  mapToPromotionPopupForms,
  mapToPromotionPopups,
} from '../PromotionPopup/mapper';
import { PromotionModal } from '../PromotionModal/PromotionModal';
import { PromotionFormModal } from '../PromotionModal/PromotionFormModal';
import { checkDesignForPromoService } from '@services/BasketService';
import { mapToAnnouncementItems, mapToTopNavMenu } from '../Layout/mapper';
import { RootLoaderProps } from '~/root';
import { Link } from '@remix-run/react';
import { MobilePageHeader } from '../PageHeader';
import FooterWrapper from '../Footer/Footer';
import { Hyperlink } from '../elements';
//import UserLink from "../elements/UserLink/UserLink"
import { LayoutContainer } from './DesktopLayoutContainer.styles';
import ShoppingCartLink from '../ShoppingCartLink/ShoppingCartLink';
import { getEmptyBasket } from '~/stateManagement/localStorage/basket';
// import { getEmptyCheckout } from "~/stateManagement/localStorage/checkout"
// import { useOrderContext } from "~/contexts/OrderContext"
import { BasketResponseSummary } from '~/dataModels/BasketModel/BasketResponseModel';
import { useCartStore } from '~/stores/cartStore';
import { useClientEnv } from '~/contexts/ClientEnvContext';
import darkTheme from '~/styles/dark-theme';

interface MobileLayoutProps {
  beforeCloseFired: boolean;
  children: React.ReactNode;
  data: RootLoaderProps;
  version: string;
  currentStep?: number;
  windowWidth: number;
}

const MobileLayoutContainer: React.FC<MobileLayoutProps> = (props) => {
  const [activeIndex, setActiveIndex] = React.useState(-1);
  const [sidebarOpened, setSidebarOpened] = React.useState(false);
  const [updatingMessageBanner, setUpdatingMessageBanner] =
    React.useState(false);
  const [allowDesign, setAllowDesign] = React.useState(false);
  const { apiUrl, emailValidationToken } = useClientEnv();
  // const orderContext = useOrderContext();
  // const { checkout, basket } = orderContext ?? {
  //   checkout: getEmptyCheckout(),
  //   basket: getEmptyBasket(),
  //   summary: {} as BasketResponseSummary,
  // };

  // React.useEffect(() => {
  //   const fetchData = async () => {
  //     if (basket) {
  //       if (basket.basketLines && basket.basketLines.length > 0) {
  //         const popups = mapToPromotionPopups(data.promotionPopups);
  //         let promoCode = '';
  //         popups.forEach((popup) => {
  //           if (
  //             showMessage(popup.startDate, popup.endDate) &&
  //             showPopupOnPage(popup.allowedURLs)
  //           ) {
  //             promoCode = popup.promoCode;
  //           }
  //         });
  //         if (promoCode && checkout && !checkout.isDealerOrder) {
  //           checkDesignForPromoService(
  //             data.siteMetadata.apiUrl,
  //             promoCode,
  //             basket.basketId,
  //           ).then((data) => {
  //             if (data && data.Success && data.Data) {
  //               setAllowDesign(true);
  //             }
  //           });
  //         }
  //       }
  //     }
  //   };
  //   fetchData();
  // }, [allowDesign]);

  const handleSidebarHide = () => setSidebarOpened(false);
  const handleSidebarShow = () => setSidebarOpened(true);

  const handleToggleSidebar = () => {
    setSidebarOpened((prevState) => !prevState);
  };
  const handleSidebarMenu = (index: number) => {
    const newIndex = activeIndex === index ? -1 : index;
    setActiveIndex(newIndex);
  };
  const removeMessageBanner = () => {
    setUpdatingMessageBanner(true);
    // if (typeof window !== 'undefined') {
    //   localStorage.setItem(key, "true");
    // }
    // setUpdatingMessageBanner(false);
  };
  const renderPromotionPopups = () => {
    const popups = mapToPromotionPopups(props.data.promotionPopups);
    const result: any[] = [];
    popups.forEach((popup, key) => {
      const id = popup.id.replace(' ', '');
      if (
        showMessage(popup.startDate, popup.endDate) &&
        !updatingMessageBanner &&
        showPopupOnPage(popup.allowedURLs) &&
        allowDesign
      ) {
        result.push(
          <PromotionModal
            key={key}
            hide={removeMessageBanner}
            data={popup}
            id={id}
            promoCode={popup.promoCode}
            apiUrl={apiUrl}
          />,
        );
      }
    });
    return result;
  };
  const renderPromotionPopupForms = () => {
    const popups = mapToPromotionPopupForms(props.data.competitionPopupForms);
    const forms: any[] = [];
    popups.forEach((popup, key) => {
      const id = 'desktop-' + hash(popup.name);
      const showbanner = showMessageBanner(id);
      if (
        showMessage(popup.startDate, popup.endDate) &&
        showbanner &&
        showPopupOnPage(popup.allowedURLs)
      )
        forms.push(
          <PromotionFormModal
            emailValidationToken={emailValidationToken}
            key={id}
            isMobile={false}
            apiUrl={apiUrl}
            show={props.beforeCloseFired}
            hide={removeMessageBanner}
            data={popup}
            id={id}
          />,
        );
    });
    return forms;
  };
  const renderAnnouncements = () => {
    const announcements = mapToAnnouncementItems(props.data.announcements);
    const result: any[] = [];
    announcements.forEach((ats, key) => {
      const id = hash(ats.title);
      if (showMessage(undefined, ats.expireDate) && showMessageBanner(id)) {
        result.push(
          <MessageBanner
            key={key}
            hide={removeMessageBanner}
            backgroundColor={ats.backgroundColor}
            messageHtml={ats.title}
            textColor={ats.textColor}
            id={id}
          />,
        );
      }
    });
    return result;
  };

  const { data, version, currentStep, children, windowWidth } = props;

  const topNavMenuItems = mapToTopNavMenu(data.navItems);

  return (
    <LayoutContainer {...props}>
      <SidebarPushableContainer>
        <SidebarContainer
          as={Menu}
          animation="overlay"
          onHide={handleSidebarHide}
          onShow={handleSidebarShow}
          vertical
          width="wide"
          visible={sidebarOpened}
        >
          <SidebarTitle>
            <SidebarHeader>
              <HamburgerIcon
                link
                name="close"
                size="large"
                onClick={handleSidebarHide}
              />
              <LogoLink to="/">
                <LogoIcon width={115} height={34} reverse={true} />
              </LogoLink>
              <MobileShoppingCartContainer>
                {/*<UserLink apiUrl={data.siteMetadata.apiUrl} />*/}
                <ShoppingCartLink
                  fill={theme.brand.colors.white}
                  isSimple={true}
                  apiUrl={apiUrl}
                />
              </MobileShoppingCartContainer>
            </SidebarHeader>
          </SidebarTitle>
          <SidebarNavItemsContainer>
            {topNavMenuItems.map((navmenu, idx) => {
              const { title, url, sub_nav } = navmenu;
              const isExternalUrl = url.startsWith('http');
              return sub_nav.length === 0 ? (
                isExternalUrl ? (
                  <SidebarNavItem
                    as={ExternalNavLink}
                    href={url}
                    onMouseEnter={() => handleSidebarMenu(idx)}
                    index={idx}
                    key={idx}
                  >
                    {title}
                  </SidebarNavItem>
                ) : (
                  <SidebarNavItem
                    as={Link}
                    to={url.endsWith('/') ? url : url + '/'}
                    onMouseEnter={() => handleSidebarMenu(idx)}
                    index={idx}
                    key={idx}
                  >
                    {title}
                  </SidebarNavItem>
                )
              ) : (
                <Fragment key={idx}>
                  <SidebarNavItemMenu>
                    <Accordion>
                      <NavAccordionTitle
                        active={activeIndex === idx}
                        index={idx}
                        onMouseEnter={() => handleSidebarMenu(idx)}
                      >
                        <SubMenuIcon
                          name={activeIndex === idx ? 'minus' : 'plus'}
                        />
                        {title}
                      </NavAccordionTitle>
                      <NavAccordionContent active={activeIndex === idx}>
                        <NavSubMenu borderless={true} as={List} vertical>
                          {sub_nav.map((submenu, j) => (
                            <NavSubMenuItem as={List.Item} key={idx + '' + j}>
                              <Hyperlink url={submenu.url}>
                                {submenu.title}
                              </Hyperlink>
                            </NavSubMenuItem>
                          ))}
                        </NavSubMenu>
                      </NavAccordionContent>
                    </Accordion>
                  </SidebarNavItemMenu>
                </Fragment>
              );
            })}
          </SidebarNavItemsContainer>
        </SidebarContainer>
        <Sidebar.Pusher dimmed={sidebarOpened}>
          <MobileTopMessageWrapper>
            {renderAnnouncements()}
            {renderPromotionPopups()}
            {renderPromotionPopupForms()}
          </MobileTopMessageWrapper>
          <MobilePageHeader
            apiUrl={apiUrl}
            version={version}
            currentStep={currentStep}
            handleToggleSidebar={handleToggleSidebar}
            sidebarOpened={sidebarOpened}
            // checkout={checkout}
            // cartLineItems={basket.basketLines}
          />
          {/* <style dangerouslySetInnerHTML={{
              __html: `
                #___gatsby{
                    height: auto !important;
                }
                `}} /> */}
          {children}
          {version !== 'simple' && (
            <FooterWrapper
              version={version}
              backgroundColor={theme.brand.colors.black}
              customerReviewsCardBanner={data.customerReviewsCardBanner}
              footerLinks={data.footerPage}
            />
          )}
        </Sidebar.Pusher>
      </SidebarPushableContainer>
    </LayoutContainer>
  );
};

export default MobileLayoutContainer;
