import React from "react"
import { Modal,  Image } from "semantic-ui-react"
import { CompetitionPromotionForm, CompetitionPromotionFormContent, ModalCloseButton, ModalCloseButtonMobile, PromotionPopupFormModal,  } from "./PromotionModal.styles"

import {  H3, Paragraph1 } from "@styles/Global.styles"
import { PromotionPopupFormModel } from "~/dataModels/PromotionPopupForm/PromotionPopupForm"
//import { CompetitionPopupForm } from "../CompetitionForm"
import { predefinedFields } from "../elements/Forms/constants.forms"
import { CompetitionPopupForm } from "../CompetitionForm/CompetitionPopupForm"

interface Props {
  data: PromotionPopupFormModel,
  hide: (id:string)=>void,
  id:string,
  show:boolean,
  apiUrl:string,
  emailValidationToken:string
  isMobile: boolean
}

interface State {
  modalOpen: boolean
}


export class PromotionFormModal extends React.Component<Props, State>{
  constructor(props: Props) {
    super(props)
    this.state = {
        modalOpen: false
    }
  }


  render() {
    const {backgroundImage, title, subtitle, formFields, termsConditions, loadTime, campaignName, name,successMessage, errorMessage} = this.props.data;
    let t_c = predefinedFields.termsConditions;
    t_c.checkboxLabelHtml = termsConditions;
    formFields.push(t_c);
    const modalVerticalCentered = this.props.isMobile ? false : true;
    return (
      <PromotionPopupFormModal open={this.props.show} onClose={()=>this.props.hide(this.props.id)} centered={modalVerticalCentered}>
        {
          this.props.isMobile ?
          <CompetitionPromotionFormContent>
              <Modal.Description>
                <ModalCloseButtonMobile name='x' size='large' color="grey" onClick={()=>this.props.hide(this.props.id)} float="right"/>
                  <CompetitionPromotionForm>
                    <H3 textAlign="center">{title}</H3>
                    <Paragraph1 textAlign="center">{subtitle}</Paragraph1>
                    <CompetitionPopupForm emailValidationToken={this.props.emailValidationToken} id={this.props.id} apiUrl={this.props.apiUrl} fields={formFields} success={successMessage} campaignName={campaignName} competitionName={name} />
                </CompetitionPromotionForm>
              </Modal.Description>
          </CompetitionPromotionFormContent>
          :
          <CompetitionPromotionFormContent image>
              <Image  alt={backgroundImage.description} src={backgroundImage.url} />
              <Modal.Description>
                <ModalCloseButton name='x' size='large' color="grey" onClick={()=>this.props.hide(this.props.id)} float="right"/>
                  <CompetitionPromotionForm>
                    <H3 textAlign="center">{title}</H3>
                    <Paragraph1 textAlign="center">{subtitle}</Paragraph1>
                    <CompetitionPopupForm emailValidationToken={this.props.emailValidationToken} id={this.props.id} apiUrl={this.props.apiUrl} fields={formFields} success={successMessage} campaignName={campaignName} competitionName={name} />
                </CompetitionPromotionForm>
              </Modal.Description>
          </CompetitionPromotionFormContent>
        }

      </PromotionPopupFormModal>
    )
  }
}