import {
  data,
  json,
  Link,
  Links,
  Meta,
  Outlet,
  Scripts,
  ScrollRestoration,
  useLoaderData,
  useLocation,
  useMatches,
  useRouteError,
} from '@remix-run/react';
import type { LinksFunction, LoaderFunction } from '@remix-run/node';

import './tailwind.css';
import 'semantic-ui-less/semantic.less';
import { Children, useEffect, useState } from 'react';
import { GAService } from './services';
import { LayoutWrapper } from './components/Layout/Layout';
import { ClientEnv, ClientEnvProvider } from './contexts/ClientEnvContext';
import { AuthProvider } from './contexts/AuthContext';
import {
  Announcement,
  CompetitionPopupForm,
  CustomerReviewCardBanner,
  Footer,
  NavigationItemV2,
  PromotionPopup,
} from './_models';

export const links: LinksFunction = () => [
  { rel: 'preconnect', href: 'https://use.typekit.net' },
  { rel: 'stylesheet', href: '/styles/platefontv1.css' },
  { rel: 'stylesheet', href: 'https://use.typekit.net/zep7nyd.css' }, // Keep only this
];

// export const links: LinksFunction = () => [
//   { rel: 'preconnect', href: 'https://use.typekit.net' },
//   {
//     rel: 'preload',
//     href: 'https://use.typekit.net/zep7nyd.css', // TODO:
//     as: 'style',
//     crossOrigin: 'anonymous',
//   },
//   { rel: 'stylesheet', href: '/styles/platefontv1.css' },
//   { rel: 'stylesheet', href: 'https://use.typekit.net/zep7nyd.css' }, // TODO: get new embed code for font-family itc-avant-garde-gothic-pro
// ];

export type RootLoaderProps = {
  announcements: Announcement[];
  promotionPopups: PromotionPopup[];
  competitionPopupForms: CompetitionPopupForm[];
  navItems: NavigationItemV2[];
  customerReviewsCardBanner: CustomerReviewCardBanner;
  footerPage: Footer;
  // siteMetadata: {
  //   apiUrl: string;
  //   reCaptchaSiteKey: string;
  //   reCaptchaSiteKeyV2: string;
  //   emailValidationToken: string;
  //   environment: string;
  //   lastBuilt: string;
  //   GTM_ID: string;
  // };
};
export function ErrorBoundary() {
  const error = useRouteError() as Error;
  const isServerError = (error as any)?.status === 500;

  useEffect(() => {
    if (isServerError) {
      console.error('Server Error:', error);
    }
  }, [error, isServerError]);

  return (
    <html lang="en">
      <head>
        <meta charSet="utf-8" />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <Meta />
        <Links />
      </head>
      <body>
        <div>
          <h2>
            Something went wrong on{' '}
            {typeof window === 'undefined' ? 'server' : 'client'}
          </h2>

          <p>{error.stack || 'An unexpected error occurred.'}</p>
          {/* TODO: route to custom error page */}
          <Link to="/">Go back to homepage</Link>
        </div>
        <ScrollRestoration />
        <Scripts />
      </body>
    </html>
  );
}

export function Layout({ children }: { children: React.ReactNode }) {
  return (
    <html lang="en">
      <head>
        <meta charSet="utf-8" />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        {/* Initialize dataLayer early */}
        <script
          dangerouslySetInnerHTML={{
            __html: `window.dataLayer = window.dataLayer || [];`,
          }}
        />
        <Meta />
        <Links />
      </head>
      <body>
        {children}
        <ScrollRestoration />
        <Scripts />
      </body>
    </html>
  );
}

export const loader: LoaderFunction = async ({ request }) => {
  const clientEnv: ClientEnv = {
    apiUrl: process.env.API_URL || '',
    reCaptchaSiteKey: process.env.RECAPTCHA_SITEKEY_V3 || '',
    reCaptchaSiteKeyV2: process.env.RECAPTCHA_SITEKEY_V2 || '',
    emailValidationToken: process.env.EMAIL_VALIDATION_TOKEN || '',
    addressValidationToken: process.env.ADDRESS_VALIDATION_TOKEN || '',
    salesforceWebToCaseOrgid: process.env.SALESFORCE_WEBTOCASE_ORGID || '',
    salesforceWebToCaseLink: process.env.SALESFORCE_WEBTOCASE_LINK || '',
    salesforceWebToCaseSubtypeName:
      process.env.SALESFORCE_WEBTOCASE_SUBTYPE_NAME || '',
    siteUrl: process.env.SITE_URL || '',
    gtmId: process.env.GTM_ID || '',
    env: process.env.NODE_ENV,
    awsRegion: process.env.DEALER_CHECKOUT_S3_REGION || '',
    awsS3Bucket: process.env.DEALER_CHECKOUT_S3_BUCKET || '',
  };

  return data<ClientEnv>(clientEnv, {
    headers: {
      'Cache-Control': `public, max-age=${process.env.HTTP_CACHE_MAX_AGE || 300}, stale-while-revalidate=${process.env.STALE_WHILE_REVALIDATE || 3600}`,
    },
  });
};

export default function App() {
  const matches = useMatches() as Array<{ handle?: { layout?: string } }>;
  const layout = matches.find((match) => match.handle?.layout)?.handle?.layout;
  const location = useLocation();
  const data = useLoaderData<ClientEnv>();
  // Track route changes globally
  useEffect(() => {
    GAService.push({ event: 'pageview', pagePath: location.pathname });
  }, [location]);

  useEffect(() => {
    //TODO: remove this after 3 months the new website go live
    if (typeof window !== 'undefined' && 'serviceWorker' in navigator) {
      // This will check if any service worker is registered and unregister it
      navigator.serviceWorker
        .getRegistrations()
        .then((registrations) => {
          for (const registration of registrations) {
            console.log('Unregistering service worker:', registration);
            registration.unregister();
          }
          if (registrations.length > 0) {
            window.location.reload();
          }
          // if (registrations.length > 0) {
          //   Promise.all(registrations.map((r) => r.unregister())).then(() => {
          //     sessionStorage.setItem('sw-cleared', 'true');
          //     console.log('Old service workers unregistered');
          //     window.location.reload();
          //   });
          // }
          // registrations.forEach((registration) => {
          //   registration.unregister();
          // });
        })
        .catch((error) => {
          console.error('Error unregistering service workers:', error);
        });
    }
  }, []);
  useEffect(() => {
    if (data && data.gtmId) {
      const gtmScript = `
        (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start': new Date().getTime(),event:'gtm.js'});
        var f=d.getElementsByTagName(s)[0],j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
        'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
        })(window,document,'script','dataLayer','${data.gtmId}');
      `;
      const scriptElement = document.createElement('script');
      scriptElement.innerHTML = gtmScript;
      document.head.appendChild(scriptElement);
    }
  }, [data]);

  return (
    <ClientEnvProvider clientEnv={data}>
      <AuthProvider apiUrl={data.apiUrl}>
        <LayoutWrapper version={layout ? layout : 'standard'}>
          <Outlet />
          <Scripts />
          <noscript>
            <iframe
              src={`https://www.googletagmanager.com/ns.html?id=${data?.gtmId}`}
              height="0"
              width="0"
              style={{ display: 'none', visibility: 'hidden' }}
            ></iframe>
          </noscript>
        </LayoutWrapper>
      </AuthProvider>
    </ClientEnvProvider>
  );
}
