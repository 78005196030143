import React, { Fragment, useEffect } from 'react';
import { Icon, List } from 'semantic-ui-react';
import {
  LogoIcon,
  HeaderSegment,
  SimpleHeaderSegment,
  SimpleHeaderContainer,
  SimpleTopNavContainer,
  SimpleTopNavMessage,
  SimpleTopNavAuth,
  PhoneLink,
  MessageContainer,
  DesktopPhoneLinkWrapper,
  DesktopSingoutLinkWrapper,
  MessageContainerLink,
  SimpleShoppingCartContainer,
} from './Header.styles';
import { Container } from 'semantic-ui-react';
import { NavItem } from '@components/Layout/mapper';
import { BasketLineResponseModel } from '~/dataModels/BasketModel/BasketLineResponseModel';
// import { Checkout } from "@dataModels/Checkout/Checkout"
// import { updateDealerAddress, updateDeliveryAddress, updateIsAcceptStatement, updateIsDealerOrder, updatePersonalDetails } from "@redux/actions/checkout"
import { Link, redirect, useNavigate } from '@remix-run/react';
import ShoppingCartContainer from '../ShoppingCartLink/ShoppingCartLink';
import { NavigationMenuDropdown, NavigationMenuLink } from '../elements';
import { PhoneNavigation } from '~/assets/svgs';
import { NavigationMenuButton } from '../elements/NavigationMenuLink/NavigationMenuLink.styles';
import UserLink from '../elements/UserLink/UserLink';
import theme from '~/styles/theme';
import { ProgressStatusBar } from '../ProgressStatusBar';
import { useAuthContext } from '~/contexts/AuthContext';
import { useCartStore } from '~/stores/cartStore';
// import { processSignOut } from "~/utils/Helpers"
// import useIsSignedInDealer from "~/hooks/validateUserAuth"

interface DesktopPageHeaderProps {
  apiUrl: string;
  topNavMenu: NavItem[];
  version: string;
  currentStep?: number;
  // cartLineItems: BasketLineResponseModel[];
  // checkout: Checkout;
  // updatePersonalDetails: (personalDetails: Checkout) => void
  // updateDeliveryAddress: (deliveryAddress: Address) => void
  // updateDealerAddress: (dealerAddress: Address) => void
  // updateIsDealerOrder: (isDealerOrder: boolean) => void
  // updateIsAcceptStatement: (isAcceptStatement: boolean) => void
}

const DesktopPageHeaderContainer: React.FunctionComponent<
  DesktopPageHeaderProps
> = (props) => {
  const { isAuthLoading, isUserLoggedIn, user } = useAuthContext();
  const { basket, getTotalItems } = useCartStore();

  useEffect(() => {
    if (
      props.version === 'simple' &&
      props.currentStep &&
      props.currentStep > 2 &&
      props.currentStep <= 4 &&
      getTotalItems() < 1
    ) {
      redirect('/shopping-cart');
    }
  }, [props.version, props.currentStep]);

  const { version, currentStep, topNavMenu, apiUrl } = props;
  // let isDealerOrder = false;
  // if (props.checkout && props.checkout.isDealerOrder) {
  //   isDealerOrder = props.checkout.isDealerOrder;
  // }

  return (
    <Fragment>
      {(version === 'simple' || version === 'darkSimple') && (
        <Fragment>
          <SimpleHeaderSegment basic>
            <SimpleHeaderContainer>
              <div className="flex justify-between items-center">
                <Link
                  to={
                    isUserLoggedIn && user.isDealer === true
                      ? '/dealer-login'
                      : '/'
                  }
                  aria-label="Homepage"
                >
                  <LogoIcon reverse={true} />
                </Link>
                <SimpleTopNav
                  apiUrl={apiUrl}
                  // checkout={props.checkout}
                  // updatePersonalDetails={props.updatePersonalDetails}
                  // updateDeliveryAddress={props.updateDeliveryAddress}
                  // updateDealerAddress={props.updateDealerAddress}
                  // updateIsDealerOrder={props.updateIsDealerOrder}
                  // updateIsAcceptStatement={props.updateIsAcceptStatement}
                />
              </div>
            </SimpleHeaderContainer>
          </SimpleHeaderSegment>
          {currentStep !== undefined && (
            <ProgressStatusBar currentStep={currentStep} isMobile={false} />
          )}
        </Fragment>
      )}
      {version === 'authSimple' && (
        <Fragment>
          <SimpleHeaderSegment basic>
            <SimpleHeaderContainer>
              <div className="flex justify-between items-center">
                <Link to="/" aria-label="Homepage">
                  <LogoIcon reverse={true} />
                </Link>
                <TopNavMenu
                  topNavMenu={topNavMenu}
                  apiUrl={apiUrl}
                  darkTheme={true}
                />
              </div>
            </SimpleHeaderContainer>
          </SimpleHeaderSegment>
        </Fragment>
      )}
      {(version === 'standard' ||
        version === 'blank' ||
        version === 'dark') && (
        <HeaderSegment basic>
          <Container>
            <div className="flex justify-between items-center">
              <div className="flex gap-4">
                <Link
                  to={
                    isUserLoggedIn && user.isDealer == true
                      ? '/dealer-login'
                      : '/'
                  }
                  aria-label="Homepage"
                >
                  <LogoIcon reverse={true} />
                </Link>
              </div>
              <div>
                <TopNavMenu
                  topNavMenu={topNavMenu}
                  apiUrl={apiUrl}
                  darkTheme={version === 'dark' ? true : false}
                />
              </div>
            </div>
          </Container>
        </HeaderSegment>
      )}
      {version === 'dealer' && (
        <HeaderSegment basic>
          <SimpleHeaderContainer>
            <div className="flex justify-between items-center">
              <Link to="/dealer-login" aria-label="Homepage">
                <LogoIcon reverse={true} />
              </Link>
              <DealerTopNav apiUrl={apiUrl} />
            </div>
          </SimpleHeaderContainer>
        </HeaderSegment>
      )}
    </Fragment>
  );
};

export default DesktopPageHeaderContainer;

interface TopNavMenuProps {
  topNavMenu: NavItem[];
  apiUrl: string;
  darkTheme: boolean;
}

export const isMarketplacePages = () => {
  const url = typeof window !== 'undefined' ? window.location.href : '';
  const marketplaceUrlsKeywords = [
    'marketplace',
    'listing-detail',
    'report-listing',
    'login',
    'app/',
  ];
  let isMarketplacePage = false;
  for (let keyword of marketplaceUrlsKeywords) {
    if (url.includes(keyword)) {
      isMarketplacePage = true;
      break;
    }
  }
  return isMarketplacePage;
};
const TopNavMenu = ({ topNavMenu, apiUrl, darkTheme }: TopNavMenuProps) => {
  const isCurrentPageMarketplacePage = isMarketplacePages();

  return (
    <List horizontal floated="right">
      {topNavMenu
        .sort((a, b) => a.order - b.order)
        .map((navmenu, i) => {
          const { title, url, sub_nav } = navmenu;
          return sub_nav.length !== 0 ? (
            <NavigationMenuDropdown key={i} text={title} linkList={sub_nav} />
          ) : (
            <NavigationMenuLink key={i} text={title} linkUrl={url} />
          );
        })}

      <NavigationMenuButton
        ignoreMinWidth={true}
        id="create_nav_item"
        link="/create/"
        buttonType="primary"
        textColor="black"
        color="#3DAE2B"
        darkTheme={darkTheme}
      >
        CREATE A PLATE
      </NavigationMenuButton>
      {isCurrentPageMarketplacePage && <UserLink apiUrl={apiUrl} />}
      <ShoppingCartContainer
        fill={theme.brand.colors.white}
        isSimple={true}
        apiUrl={apiUrl}
      />
    </List>
  );
};

interface SimpleTopNavProps {
  apiUrl: string;
  // checkout: Checkout;
  // updatePersonalDetails: (personalDetails: Checkout) => void
  // updateDeliveryAddress: (deliveryAddress: Address) => void
  // updateDealerAddress: (dealerAddress: Address) => void
  // updateIsDealerOrder: (isDealerOrder: boolean) => void
  // updateIsAcceptStatement: (isAcceptStatement: boolean) => void
}

function SimpleTopNav(props: SimpleTopNavProps): JSX.Element {
  const { user, isUserLoggedIn, logout } = useAuthContext();
  const navigate = useNavigate();
  // const { isDealer } = useIsSignedInDealer();
  const handleSignOut = async () => {
    await logout();
    navigate('/dealer-login');
  };

  return (
    <SimpleTopNavContainer>
      {isUserLoggedIn && user.isDealer === true ? (
        <>
          <SimpleTopNavAuth onClick={handleSignOut}>
            <MessageContainer>Dealer Logout</MessageContainer>
            <DesktopSingoutLinkWrapper>
              <Icon name="sign-out" size="big" />
            </DesktopSingoutLinkWrapper>
          </SimpleTopNavAuth>
          <SimpleShoppingCartContainer>
            <ShoppingCartContainer
              fill={theme.brand.colors.white}
              isSimple={true}
              apiUrl={props.apiUrl}
            />
          </SimpleShoppingCartContainer>
        </>
      ) : (
        <>
          <SimpleTopNavMessage>
            <MessageContainer>
              Need any help? Call us on{' '}
              <MessageContainerLink href="tel:0800650111">
                0800 650 111
              </MessageContainerLink>
            </MessageContainer>
            <DesktopPhoneLinkWrapper>
              <PhoneLink href="tel:0800650111">
                <PhoneNavigation />
              </PhoneLink>
            </DesktopPhoneLinkWrapper>
          </SimpleTopNavMessage>
          <SimpleShoppingCartContainer>
            <ShoppingCartContainer
              fill={theme.brand.colors.white}
              isSimple={true}
              apiUrl={props.apiUrl}
            />
          </SimpleShoppingCartContainer>
        </>
      )}
    </SimpleTopNavContainer>
  );
}

const DealerTopNav = ({ apiUrl }: { apiUrl: string }) => {
  return (
    <SimpleTopNavContainer>
      <SimpleShoppingCartContainer>
        <ShoppingCartContainer
          fill={theme.brand.colors.white}
          isSimple={true}
          apiUrl={apiUrl}
        />
      </SimpleShoppingCartContainer>
    </SimpleTopNavContainer>
  );
};
