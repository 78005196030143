import React from "react"
import { KpFormikCheckboxFieldProps } from "./types"
import { Field } from "formik"
import { CheckboxInput,CheckboxText } from "./forms.styles"
import { KpFormInputErrorMessage } from "@styles/Global.forms";

import get from 'lodash/get';
/* eslint-disable jsx-a11y/label-has-associated-control */

export const KpCheckboxFormikField = (props: KpFormikCheckboxFieldProps) =>{
      
    return <div><Field name={props.name}>
                {({field, form}:{field:any,form:any}) => {
                return <label>
                    <CheckboxInput
                        type="checkbox"
                        {...props}
                        checked={field.value.includes(props.value)}
                        onChange={() => {
                            if (field.value.includes(props.value)) {
                            const nextValue = field.value.filter(
                                (value:any) => value !== props.value
                            );
                            form.setFieldValue(props.name, nextValue);
                            } else {
                            const nextValue = field.value.concat(props.value);
                            form.setFieldValue(props.name, nextValue);
                            }
                        }}
                    />
                    <CheckboxText id="___gatsby" dangerouslySetInnerHTML={{__html: props.text}} />
                </label>
                }}
            </Field>
            <KpFormInputErrorMessage>{get(props.errors, props.name)}</KpFormInputErrorMessage>
            </div>
  }