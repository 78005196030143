import { Fragment, useState } from 'react';
import { Grid, Popup } from 'semantic-ui-react';
import { H6 } from '@styles/Global.styles';
import {
  CartDivider,
  CartGridRow,
  ShoppingCartButton,
  CartGridColumn,
  CartItemSummary,
  PopupDetailWrapper,
  SummaryCategoryName,
  SummaryCombination,
  SummaryPrice,
  DeleteIcon,
  ShoppingCartButtonWithBadge,
  PopupEmptyCart,
} from './ShoppingCartLink.styles';
import { buildPlateImageInfoFromBasket, isMobile } from '@utils/Helpers';
import { ShoppingCart, ShoppingCartBadge } from '~/assets/svgs';
import {
  PlateImage,
  PlateImageContainer,
} from '../HomepagePopularPlatesCarousel/PlateDesignStyleContainer.styles';
import { KpButton } from '../elements';
import theme from '~/styles/theme';
import { VehicleTypesSetting } from '~/dataModels/ProductModels';
import {
  BasketResponseModel,
  BasketResponseSummary,
} from '~/dataModels/BasketModel/BasketResponseModel';
import { BasketLineResponseModel } from '~/dataModels/BasketModel/BasketLineResponseModel';
// import { useOrderContext } from "~/contexts/OrderContext"
import React from 'react';
// import { getEmptyBasket } from '~/stateManagement/localStorage/basket';
import { StateResponse, useCartStore } from '~/stores/cartStore';

interface ShoppingCartPopupProps {
  basketId?: string;
  basketLineItems?: BasketLineResponseModel[];
  summary?: BasketResponseSummary;
  apiUrl: string;
  delete: (
    apiUrl: string,
    basketId: string,
    basketLineId: string,
  ) => Promise<StateResponse>;
}

const ShoppingCartPopup = (props: ShoppingCartPopupProps) => {
  return (
    <Fragment>
      <Popup.Header>
        <H6>Your Cart</H6>
        <CartDivider />
      </Popup.Header>
      <Popup.Content>
        {props.basketLineItems && props.basketLineItems.length > 0 ? (
          <ShoppingCartPopupDetail {...props} />
        ) : (
          <PopupDetailWrapper>
            <PopupEmptyCart>Your cart is Empty!</PopupEmptyCart>
          </PopupDetailWrapper>
        )}
      </Popup.Content>
    </Fragment>
  );
};

const ShoppingCartPopupDetail = (props: ShoppingCartPopupProps) => {
  const basketId = props.basketId ? props.basketId : '';
  const apiUrl = props.apiUrl;
  return (
    <PopupDetailWrapper>
      {basketId !== '' && (
        <Fragment>
          <Grid>
            {props.basketLineItems &&
              props.basketLineItems.map((item, key) => {
                const isCar = item.vehicleTypeId === VehicleTypesSetting.Car.id;
                const info = buildPlateImageInfoFromBasket(apiUrl, item, 150);
                return (
                  <CartGridRow key={key}>
                    <CartGridColumn width={7}>
                      <PlateImageContainer>
                        <PlateImage
                          src={info.frontUrl}
                          centered
                          width={info.frontWidth}
                          alt="front plate image"
                        />
                      </PlateImageContainer>
                      {isCar && (
                        <PlateImageContainer>
                          <PlateImage
                            src={info.backUrl}
                            centered
                            width={info.backWith}
                            alt="back plate image"
                          />
                        </PlateImageContainer>
                      )}
                    </CartGridColumn>
                    <CartGridColumn width={8}>
                      <CartItemSummary>
                        <SummaryCategoryName>
                          {item.frontPlate.rangeName}
                        </SummaryCategoryName>
                        <SummaryCombination>
                          {item.combination}
                        </SummaryCombination>
                        <SummaryPrice>
                          ${item.priceInclGst.toFixed(2)}
                        </SummaryPrice>
                      </CartItemSummary>
                    </CartGridColumn>
                    <CartGridColumn width={1}>
                      <DeleteIcon
                        color="red"
                        name="trash alternate"
                        onClick={() =>
                          props.delete(apiUrl, basketId, item.basketLineId)
                        }
                      />
                    </CartGridColumn>
                  </CartGridRow>
                );
              })}
          </Grid>
          <Grid>
            <Grid.Row>
              <Grid.Column width={8} textAlign="left">
                <SummaryCategoryName>Total</SummaryCategoryName>
              </Grid.Column>
              <Grid.Column width={8} textAlign="right">
                <SummaryCategoryName>
                  ${props.summary && props.summary.finalTotal.toFixed(2)}
                </SummaryCategoryName>
              </Grid.Column>
            </Grid.Row>
            <Grid.Row>
              <Grid.Column width={16} textAlign="center">
                <KpButton
                  id="sclco"
                  fullWidth="mobile tablet computer"
                  buttonType="primary"
                  color={theme.brand.colors.green}
                  link="/shopping-cart"
                >
                  CHECKOUT
                </KpButton>
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </Fragment>
      )}
    </PopupDetailWrapper>
  );
};

interface ShoppingCartLinkProps {
  isSimple?: boolean;
  basketId?: string;
  apiUrl: string;
  badgeColor?: string;
  fill?: string;
}

const ShoppingCartIcon = ({
  count,
  isSimple,
  badgeColor,
  fill,
}: {
  count: number;
  isSimple?: boolean;
  badgeColor?: string;
  fill?: string;
}) => {
  return (
    <Fragment>
      {count === 0 ? (
        <div
          style={isMobile() ? { paddingTop: '7px', paddingLeft: '14px' } : {}}
        >
          <ShoppingCart fill={fill} />
        </div>
      ) : (
        <ShoppingCartBadge
          fill={fill}
          badgeColor={badgeColor}
          counter={count}
          isSimple={isSimple}
        />
      )}
    </Fragment>
  );
};

export default function ShoppingCartLink(
  props: ShoppingCartLinkProps,
): JSX.Element {
  const { basket, deleteBasketLine, getTotalItems } = useCartStore();
  return (
    <Fragment>
      {getTotalItems() === 0 ? (
        <Popup
          trigger={
            <ShoppingCartButton>
              <ShoppingCartIcon
                fill={props.fill}
                badgeColor={props.badgeColor}
                count={getTotalItems()}
                isSimple={props.isSimple}
              />
            </ShoppingCartButton>
          }
          flowing
          on="click"
          position="bottom right"
        >
          <ShoppingCartPopup
            basketId={basket ? basket.basketId : ''}
            basketLineItems={basket ? basket.basketLines : []}
            summary={basket ? basket.summary : undefined}
            apiUrl={props.apiUrl}
            delete={deleteBasketLine}
          />
        </Popup>
      ) : (
        <Popup
          trigger={
            <ShoppingCartButtonWithBadge>
              <ShoppingCartIcon
                fill={props.fill}
                badgeColor={props.badgeColor}
                count={getTotalItems()}
                isSimple={props.isSimple}
              />
            </ShoppingCartButtonWithBadge>
          }
          flowing
          on="click"
          position="bottom right"
        >
          <ShoppingCartPopup
            basketId={basket ? basket.basketId : ''}
            basketLineItems={basket ? basket.basketLines : []}
            summary={basket ? basket.summary : undefined}
            apiUrl={props.apiUrl}
            delete={deleteBasketLine}
          />
        </Popup>
      )}
    </Fragment>
  );
}
