//import { ThemeProvider } from '@emotion/react';
import { ThemeProvider } from '@emotion/react';
import darkTheme from '~/styles/dark-theme';
import theme from '~/styles/theme';

export const ThemeWrapper = ({children,themeName = "default"}:{children: React.ReactNode, themeName: string}) => (
  themeName == "default" ? <ThemeProvider theme={theme}>{children}</ThemeProvider> : <ThemeProvider theme={darkTheme}>{children}</ThemeProvider>
);

export default ThemeWrapper;
