import { FunctionComponent } from 'react';


interface Props{
  fill?: string,
  width?: number
  height?: number
}

const PhoneNavigation: FunctionComponent<Props> = (props) =>{
  const width = '' + props.width;
  const height = '' + props.height;
    return (
      <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 26 26">
        <g data-name="Navigation Phone" transform="translate(-642 -22)">
          <g transform="translate(649.016 29)">
            <path data-name="Path 67" d="M11.984,9.3q-.051-.153-.648-.452-.162-.094-.46-.256t-.541-.3q-.243-.136-.456-.264Q9.845,8,9.666,7.879a2.4,2.4,0,0,0-.3-.183.558.558,0,0,0-.243-.06.707.707,0,0,0-.426.243,4.15,4.15,0,0,0-.469.529,5.139,5.139,0,0,1-.452.528.65.65,0,0,1-.392.243.58.58,0,0,1-.192-.043c-.077-.028-.135-.053-.175-.072s-.108-.06-.2-.12-.151-.092-.162-.1a8.957,8.957,0,0,1-2-1.483,8.952,8.952,0,0,1-1.483-2q-.009-.017-.1-.162t-.119-.2a1.847,1.847,0,0,1-.072-.175.577.577,0,0,1-.043-.192.65.65,0,0,1,.243-.392A5.133,5.133,0,0,1,3.6,3.784a4.148,4.148,0,0,0,.528-.469.707.707,0,0,0,.243-.426.557.557,0,0,0-.06-.243,2.369,2.369,0,0,0-.183-.3q-.124-.179-.149-.213-.128-.213-.264-.456t-.3-.541q-.162-.3-.256-.46-.3-.6-.452-.648A.477.477,0,0,0,2.533,0a2.808,2.808,0,0,0-.6.085,3.375,3.375,0,0,0-.584.179,2.285,2.285,0,0,0-.9,1.04A3.3,3.3,0,0,0,.01,2.889a3.291,3.291,0,0,0,.03.447,3.677,3.677,0,0,0,.107.49q.077.273.124.4T.445,4.7q.128.341.153.418a7.72,7.72,0,0,0,.707,1.492A12.637,12.637,0,0,0,3.142,8.868,12.643,12.643,0,0,0,5.4,10.7a7.732,7.732,0,0,0,1.492.707q.077.025.418.153t.473.175q.132.047.4.124a3.71,3.71,0,0,0,.49.107,3.161,3.161,0,0,0,2.033-.4,2.283,2.283,0,0,0,1.04-.9,3.357,3.357,0,0,0,.179-.584,2.8,2.8,0,0,0,.085-.6A.475.475,0,0,0,11.984,9.3Z" transform="translate(0 0)" fill={props.fill}/>
          </g>
          <g data-name="Path 671" transform="translate(642 22)" fill="none">
            <path d="M13,0A13,13,0,1,1,0,13,13,13,0,0,1,13,0Z" stroke="none"/>
            <path d="M 13 2.399999618530273 C 7.155139923095703 2.399999618530273 2.399999618530273 7.155139923095703 2.399999618530273 13 C 2.399999618530273 18.8448600769043 7.155139923095703 23.60000038146973 13 23.60000038146973 C 18.8448600769043 23.60000038146973 23.60000038146973 18.8448600769043 23.60000038146973 13 C 23.60000038146973 7.155139923095703 18.8448600769043 2.399999618530273 13 2.399999618530273 M 13 0 C 20.1796989440918 0 26 5.82029914855957 26 13 C 26 20.1796989440918 20.1796989440918 26 13 26 C 5.82029914855957 26 0 20.1796989440918 0 13 C 0 5.82029914855957 5.82029914855957 0 13 0 Z" stroke="none" fill={props.fill}/>
          </g>
        </g>
      </svg>

    )
}

PhoneNavigation.defaultProps = {
  fill: "#fff",
  width: 26,
  height: 26
}

export default PhoneNavigation