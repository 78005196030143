

import theme from '@styles/theme';
import { FunctionComponent } from 'react';

interface Props{
    fill?: string,
    width?: number,
    height?: number,
    badgeColor?: string,
    counter: number,
    isSimple?: boolean
  }
const ShoppingCartBadge: FunctionComponent<Props> = ({
    badgeColor = "#3dae2b",
    width = 36,
    height = 33,
    isSimple = false,
    counter,
    fill
}) => {
    const finalWidth = '' + width;
    const finalHeight = '' + height;
    const finalBadgeColor = isSimple ? theme.brand.colors.green : badgeColor;
    const singleTrans = {
        x: "-4", 
        y: "1",
        size: "13"
    };
    const doubleTrans = {
        x: "-7",
        y: "0",
        size: "10"
    }
    const transConfig = counter > 9 ? doubleTrans : singleTrans;
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width={finalWidth} height={finalHeight} viewBox="0 0 36 33">
            <g transform="translate(0.001 8)">
                <path d="M25.719,4.891a1.316,1.316,0,0,0-1.028-.5H6.3L5.431.981A1.309,1.309,0,0,0,4.166,0H1.307a1.308,1.308,0,1,0,0,2.615H3.15L6.406,15.278a1.309,1.309,0,0,0,1.266.981H22.4a1.315,1.315,0,0,0,1.272-.992L25.96,6.009a1.3,1.3,0,0,0-.241-1.118ZM21.381,13.65H8.688L6.982,7.006H23.027Z" fill={fill}/>
                <path d="M92.618,286.6a3.518,3.518,0,1,0,3.518,3.518A3.518,3.518,0,0,0,92.618,286.6Zm0,4.653a1.141,1.141,0,1,1,1.141-1.141A1.141,1.141,0,0,1,92.618,291.253Z" transform="translate(-83.805 -269.569)" fill={fill} />
                <path d="M286.318,286.6a3.518,3.518,0,1,0,3.518,3.518A3.518,3.518,0,0,0,286.318,286.6Zm0,4.653a1.141,1.141,0,1,1,1.141-1.141,1.141,1.141,0,0,1-1.141,1.141Z" transform="translate(-265.995 -269.569)" fill={fill}/>
            </g>
            <circle cx="6" cy="10" r="10" transform="translate(16 1)" fill={finalBadgeColor}/>
            <text transform="translate(22 15)" fill={fill} fontSize={transConfig.size} fontFamily="itc-avant-garde-gothic-pro, sans-serif" fontWeight="600"><tspan x={transConfig.x} y={transConfig.y}>{counter}</tspan></text>
        </svg>
        // <svg id="Shopping_Cart" data-name="Shopping Cart" xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 26 24.067">
        // <path id="Path_259" data-name="Path 259" d="M25.719,4.891a1.316,1.316,0,0,0-1.028-.5H6.3L5.431.981A1.309,1.309,0,0,0,4.166,0H1.307a1.307,1.307,0,0,0,0,2.615H3.15L6.406,15.278a1.309,1.309,0,0,0,1.266.981H22.4a1.315,1.315,0,0,0,1.272-.992l2.288-9.258A1.3,1.3,0,0,0,25.719,4.891ZM21.381,13.65H8.688L6.982,7.006H23.027Z" fill={props.fill}/>
        // <path id="Path_260" data-name="Path 260" d="M92.618,286.6a3.518,3.518,0,1,0,3.518,3.518A3.521,3.521,0,0,0,92.618,286.6Zm0,4.653a1.141,1.141,0,1,1,1.141-1.141A1.141,1.141,0,0,1,92.618,291.253Z" transform="translate(-83.805 -269.569)" fill={props.fill}/>
        // <path id="Path_261" data-name="Path 261" d="M286.318,286.6a3.518,3.518,0,1,0,3.518,3.518A3.521,3.521,0,0,0,286.318,286.6Zm0,4.653a1.141,1.141,0,1,1,1.141-1.141A1.141,1.141,0,0,1,286.318,291.253Z" transform="translate(-265.995 -269.569)" fill={props.fill} />
        // </svg>
    )
}

export default ShoppingCartBadge;