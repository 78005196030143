//import { predefinedFields } from "@components/elements/Forms/constants.forms";
import { FormFieldData } from '../elements/Forms/types';
import {
  PromotionPopupModel,
  PromotionPopupKenticoModel,
} from '@dataModels/PromotionPopup/PromotionPopup';
import {
  PromotionPopupFormKenticoModel,
  PromotionPopupFormModel,
} from '@dataModels/PromotionPopupForm/PromotionPopupForm';
import { KenticoChoice } from '@utils/KontentTypes';
import { predefinedFields } from '../elements/Forms/constants.forms';
import { CompetitionPopupForm, PromotionPopup } from '~/_models';

const loadSelectedFields = (data: Array<KenticoChoice>) => {
  const fields: FormFieldData[] = [];
  data.forEach((item) => {
    if (item.codename === 'first_name') {
      fields.push(predefinedFields.firstname_simplify);
    } else if (item.codename === 'last_name') {
      fields.push(predefinedFields.lastname_simplify);
    } else if (item.codename === 'email') {
      fields.push(predefinedFields.email_simplify);
    }
  });
  return fields;
};

export const mapToPromotionPopups = (
  kenticoData: PromotionPopup[],
): PromotionPopupModel[] => {
  return kenticoData.map((kenticoData: PromotionPopup) => {
    return {
      BackgroundImage: {
        description:
          kenticoData.elements.backgroundImage.value[0].description ?? '',
        url: kenticoData.elements.backgroundImage.value[0].url ?? '',
      },
      id: kenticoData.system.name,
      endDate: kenticoData.elements.endDate.value ?? '',
      startDate: kenticoData.elements.startDate.value ?? '',
      linkUrl: kenticoData.elements.linkUrl.value ?? '',
      allowedURLs: kenticoData.elements.urlSToShowOn.value.map(
        (url: { codename: string; name: string }) => {
          return url.name;
        },
      ),
      promoCode: kenticoData.elements.promoCode.value ?? '',
    };
  });
};
export const mapToPromotionPopupForms = (
  kenticoData: CompetitionPopupForm[],
): PromotionPopupFormModel[] => {
  return kenticoData.map((data: CompetitionPopupForm) => {
    const model: PromotionPopupFormModel = {
      name: data.system.name,
      backgroundImage: {
        description: data.elements.backgroundImage.value[0]?.description ?? '',
        url: data.elements.backgroundImage.value[0]?.url ?? '',
      },
      title: data.elements.competitionTitle.value ?? '',
      subtitle: data.elements.competitionSubtitle.value ?? '',
      campaignName: data.elements.campaignName.value ?? '',
      startDate: data.elements.startDate.value ?? '',
      endDate: data.elements.endDate.value ?? '',
      loadTime: data.elements.loadTime.value[0].codename ?? '',
      termsConditions: data.elements.tC.value ?? '',
      formFields: loadSelectedFields(data.elements.competitionFormFields.value),
      allowedURLs: data.elements.pageToShowOn.value.map(
        (url: { codename: string; name: string }) => {
          return url.name;
        },
      ),
      successMessage: data.elements.submitSuccessMessage.value,
      errorMessage: data.elements.submitErrorMessage.value,
    };
    return model;
  });
};
